import { graphql, useStaticQuery } from "gatsby"

export default function IndexData() {
  return useStaticQuery(graphql`
    query queryIndex {
      seo: sanitySeo(slug: { current: { eq: "home" } }) {
        meta_title
        meta_description
        keywords
        meta_image {
          asset {
            url
          }
        }
        alt_tag
      }

      hero: sanityHero(slug: { current: { eq: "main-hero" } }) {
        title
        hints {
          parts
        }
        svgUpload
        deleteAllHintText
        isHintsAnimation
        backgroundColor {
          hex
        }
      }
      video: sanityVideoSection(slug: { current: { eq: "our-raison" } }) {
        description
        quote
        title
        slug {
          current
        }
        hints {
          parts
        }
        video_link
        backgroundColor {
          hex
        }
        isHintsAnimation
      }
      features: sanityFeatures(slug: { current: { eq: "why-devwiz" } }) {
        id
        hints {
          parts
        }
        title
        slide
        features {
          title
          icon {
            asset {
              url
            }
          }
          info
        }
        backgroundColor {
          hex
        }
        isHintsAnimation
      }
      works: sanityWorks(slug: { current: { eq: "recent-work" } }) {
        title
        hints {
          parts
        }
        clients {
          case_card {
            slug {
              current
            }
            logo {
              asset {
                url
              }
            }
            name
            description
            technologies {
              name
              logo {
                asset {
                  url
                }
              }
            }
          }
        }
        backgroundColor {
          hex
        }
        isHintsAnimation
      }
      solutions: sanitySolution(
        slug: { current: { eq: "software-solutions" } }
      ) {
        title
        hints {
          parts
        }
        steps {
          name
          process {
            name
            description
          }
        }
        backgroundColor {
          hex
        }
        isHintsAnimation
      }
      stack: sanityStack(slug: { current: { eq: "tech-stack" } }) {
        images {
          asset {
            url
          }
        }
        title
        hints {
          parts
        }
        tech_stacks {
          title
          description
          techs {
            name
            logo {
              asset {
                url
              }
            }
          }
        }
        backgroundColor {
          hex
        }
        isHintsAnimation
      }
    }
  `)
}
