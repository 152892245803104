import React from "react"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Seo from "../components/seo"
import Hero from "../sections/hero/hero"
import Layout from "../components/Layout"
import Objective from "../sections/video_section/Video"
import RecentWork from "../sections/works/recentWork"
import Features from "../sections/features/features"
import Solutions from "../sections/solutions/solutions"
import Stack from "../sections/tech/stack"
import * as hero_styles from "../sections/hero/hero_index.module.scss"
import * as video_styles from "../sections/video_section/video_index.module.scss"
import * as feature_styles from "../sections/features/features_index.module.scss"
import * as solutions_styles from "../sections/solutions/solutions_index.module.scss"
import * as tech_styles from "../sections/tech/tech_index.module.scss"
import * as works_styles from "../sections/works/works_index.module.scss"
import IndexData from "../utils/index-queries"
import { getSeo } from "../utils/seo"

export default function Home() {
  const { hero, video, features, works, solutions, stack } = IndexData()

  return (
    <Layout navBG={hero?.backgroundColor ? hero.backgroundColor.hex : ""}>
      {/* <Seo
        title={homepage.title}
        keywrods={homepage.keywrods}
        description={homepage.description}
      /> */}
      <Hero
        hero={hero}
        styles={hero_styles}
        nextSection={video?.slug?.current}
      />
      <Objective content={video} styles={video_styles} />
      <Features features={features} styles={feature_styles} />
      <Solutions content={solutions} styles={solutions_styles} />
      <Stack stack={stack} styles={tech_styles} />
      <RecentWork works={works} styles={works_styles} />
    </Layout>
  )
}

export const Head = () => {
  const { seo } = IndexData()
  return getSeo(seo)
}
