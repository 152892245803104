// extracted by mini-css-extract-plugin
export var appSlider = "video_index-module--app-slider--2kI7p";
export var contentWrapper = "video_index-module--content-wrapper--1X_No";
export var defaultContainer = "video_index-module--default-container--2XNu9";
export var featureSlider = "video_index-module--feature-slider--KH4KG";
export var history = "video_index-module--history--1jrPP";
export var innerPagesSlider = "video_index-module--inner-pages-slider--3qdGb";
export var leftAppSlide = "video_index-module--left-app-slide--X4KC7";
export var logoSlider = "video_index-module--logo-slider--3PDoZ";
export var objective = "video_index-module--objective--1WEib";
export var objectiveContainer = "video_index-module--objective-container--1BjP6";
export var objectiveWrapper = "video_index-module--objective-wrapper--1cUiB";
export var phoneAppSelect = "video_index-module--phone-app-select--3gGvx";
export var phoneCaseSelect = "video_index-module--phone-case-select--2U5Et";
export var quotes = "video_index-module--quotes--3SMp2";
export var rightAppSlide = "video_index-module--right-app-slide--WCV4m";
export var slickActive = "video_index-module--slick-active--tW5XI";
export var slickArrow = "video_index-module--slick-arrow--1aWlA";
export var slickCenter = "video_index-module--slick-center--1-Q2Y";
export var slickDots = "video_index-module--slick-dots--1Mlxc";
export var slickNext = "video_index-module--slick-next--UJbTf";
export var slickPrev = "video_index-module--slick-prev--DX68s";
export var slickSlide = "video_index-module--slick-slide--FEM4-";
export var slickSlider = "video_index-module--slick-slider--2Gv-W";
export var slickTrack = "video_index-module--slick-track--42FGZ";
export var solvingSlider = "video_index-module--solving-slider--1IBo-";
export var titleContainer = "video_index-module--titleContainer--1_Hgk";
export var titleWrapper = "video_index-module--title-wrapper--P3zFJ";
export var video = "video_index-module--video--1IVEJ";
export var videoTablet = "video_index-module--video-tablet--1OeIc";