import React from "react"
import Quotes from "../../../assets/quotes.svg"
import { replaceSpacing } from "../../utils/functions"
import { useState } from "react"
import {
  animationSlideRight,
  animationFade,
} from "../../utils/scroll-animation"
import Hints from "../../common/hints/hints"
import thumbnail from "../../images/video.png"
export default function Video(props) {
  const { content, styles } = props
  const [thumb, setThumb] = useState(true)
  const renderQoute = () => {
    return (
      content?.quote && (
        <div className={styles.titleContainer}>
          <span className={styles.quotes}>
            <Quotes />
          </span>
          <h1>{replaceSpacing(content.quote)}</h1>
        </div>
      )
    )
  }

  return (
    <section
      className={styles.objective}
      id={content?.slug?.current}
      style={{
        backgroundColor: content?.backgroundColor
          ? content.backgroundColor.hex
          : "",
      }}
    >
      <div className={`default-container ${styles.objectiveWrapper}`}>
        <div className={styles.objectiveContainer}>
          <div className={styles.titleWrapper}>
            <h1 {...animationSlideRight()}>{replaceSpacing(content.title)}</h1>
            <Hints
              hints={content?.hints}
              isAnimation={content?.isHintsAnimation}
              typingBackground={
                content?.backgroundColor
                  ? content.backgroundColor.hex
                  : "#673895"
              }
            />
          </div>
          <div className={styles.contentWrapper}>
            <div className={styles.history} {...animationSlideRight()}>
              {renderQoute()}
              <div className={styles.videoTablet}>
                {thumb && (
                  <label onClick={() => setThumb(false)} htmlFor="video">
                    <img src={thumbnail} alt="video-icon" />
                  </label>
                )}
                <iframe
                  id="video"
                  loading="lazy"
                  className={styles.iframe}
                  src={content.video_link}
                  width="100%"
                  height="100%"
                ></iframe>
              </div>
              <p>{replaceSpacing(content.description)}</p>
            </div>
            <div className={styles.video} {...animationFade()}>
              {thumb && (
                <label onClick={() => setThumb(false)} htmlFor="video">
                  <img src={thumbnail} alt="video-icon" />
                </label>
              )}
              <iframe
                id="video"
                className={styles.iframe}
                src={content.video_link}
                width="600"
                height="400"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
